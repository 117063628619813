<template>
    <div class="box">
        <router-link
            v-if="$can('admin/power/create-role')"
            :to="{ path: 'role/create' }"
        >
            <el-button
                type="primary"
                size="medium"
            >
                新建角色
            </el-button>
        </router-link>

        <div class="box-toolbar">
            <div class="box-item">
                <el-input
                    v-model="name"
                    placeholder="请输入角色名称"
                    clearable
                    @keyup.enter.native="searchGo"
                >
                </el-input>
            </div>
            <div class="box-item">
                <el-input
                    placeholder="请输入描述"
                    v-model="description"
                    clearable
                    @keyup.enter.native="searchGo"
                >
                </el-input>
            </div>
            <div class="box-item">
                <el-button
                    icon="el-icon-search"
                    circle
                    @click="searchGo()"
                ></el-button>
            </div>
        </div>

        <el-table
            height="75vh"
            v-loading="loading"
            border
            :stripe="true"
            :data="lists"
        >
            <el-table-column
                width="150"
                prop="name"
                class-name="text-mono"
                label="角色名称"
            >
            </el-table-column>
            <el-table-column
                width="150"
                prop="description"
                label="描述"
            >
            </el-table-column>
            <el-table-column
                prop="created_at"
                label="创建时间"
            >
            </el-table-column>
            <el-table-column
                prop="updated_at"
                label="更新时间"
            >
            </el-table-column>
            <el-table-column
                width="190"
                label="操作"
            >
                <template slot-scope="scope">
                    <el-dropdown trigger="click">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                        >
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="$can('admin/power/update')"
                                @click.native="handleModelEdit(scope.row)"
                            >
                                编辑
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="$can('admin/power/delete')"
                                @click.native="handlePowerDelete(scope.row)"
                            >
                                删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <page
            :pageSize="limit"
            :count="count"
            @change="pageChange"
            @changeSize="changeSize"
        >
        </page>
    </div>
</template>

<script>
import AdminService from '@admin/services/AdminService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  components: { Page },
  data () {
    return {
      loading: true,
      function: function (param) {
        return AdminService.allRole(param)
      },
      description: '',
      name: ''
    }
  },
  mixins: [pagination],
  methods: {
    async searchGo () {
      this.page = 1
      let param = {
        name: this.name,
        description: this.description
      }
      this.where = param
      await this.getList(param)
    },
    handleModelEdit (admin) {
      this.$router.push({
        path: '/role/update',
        query: { id: admin.name }
      })
    },
    handlePowerDelete (model, msg) {
      this.$deleteDialog(
        msg || 'ID 为' + model.name + '的数据',
        async action => {
          if (action === 'confirm') {
            this.loading = true

            const data = await AdminService.delRole({
              id: model.name,
              _p_time: new Date()
            })

            if (data) {
              this.lists.forEach((item, index) => {
                if (item.name === model.name) {
                  this.lists.splice(index, 1)
                }
              })

              this.$message({
                type: 'success',
                message: data.msg
              })
              this.loading = false
            }

            this.loading = false
          }
        }
      )
    }
  }
}
</script>

<style scoped>
</style>
