import Vue from 'vue'

const AdminService = {
  all (param) {
    return Vue.http.get('member/index', param)
  },

  delete (id) {
    return Vue.http.delete('member/delete', { id: id })
  },

  create (admin = null) {
    const endpoint = 'member/create'

    if (admin === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, admin)
  },

  edit (id, admin = null) {
    const endpoint = 'member/edit'

    if (admin === null) {
      return Vue.http.get(endpoint, { id: id })
    }

    return Vue.http.put(endpoint, admin, { id: id })
  },

  // 权限管理
  // 角色管理
  allRole (param) {
    return Vue.http.get('power/role-data', param)
  },
  createRole (param = null) {
    const endpoint = 'power/create-role'
    if (param === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, param)
  },
  // edit-role
  editRole (id, admin = null) {
    const endpoint = 'power/update'

    if (admin === null) {
      return Vue.http.get(endpoint, { name: id })
    }

    return Vue.http.put(endpoint, admin, { name: id })
  },
  delRole (param) {
    return Vue.http.delete('power/delete', param)
  }
}

export default AdminService
